@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@500&display=swap');

* {
  box-sizing: border-box;
}

body,html{
  position: relative;
  height: 100%;
  text-align: center;
}

body {
  background-color: #1b1b1b;
}
  
.nav {
  background-color: #1b1b1b;
  width: 100%;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  font-size: 1rem;
}

.nav > div > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 2rem;
  order: 2;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem;
}

.nav li.active {
  background-color: #555;
  border-radius: 10px 10px 10px 10px;
}

.nav li:hover {
  background-color: #777;
  border-radius: 10px 10px 10px 10px;
}

.menu {
  text-align: justify;
  color: #fff;
  margin-left: 0;
  padding-left: 1rem;
  height: 100%;
  background-color: #1b1b1b;
  top: 0; 
}

.phone-number {
  font-size: 1.5rem;
  padding-left: 1rem;
}

.rectangle {
  float: left;
  width: .25rem;
  height: 2rem;
  background:#f20037;
  margin-right: 1rem;
}

.menu-item {
  padding: .5rem 1rem;
  margin-top: 5rem;
  width: 100%;
  font-size: 1.5rem;
  background-color: #262626;
}

.side-nav {
  padding: 0 2rem;
}

.side-nav ul {
  padding: 0;
  list-style: none;
}

.side-nav a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: .75rem 0;
}

.side-nav li.active {
  background-color: inherit;
}

.routes {
  height: 100%;
  background-color: #fff;
}

.light-beige {
  color: #a6a6a6;
}
  
.pad-right {
  padding-right: 1rem;
}

@media only screen and (min-width: 1000px) {
  .mobile {
    display: none;
  }

  #all-content {
    max-width: 1600px;
    margin: auto;
    overflow: hidden; 
  }
  
  .wrapper {
    background-color: #fff;
    text-align: left;
    margin: 0 auto;
  }

  .nav {
    float: left;
    display: flex;
  }

  #best-fireplaces-image {
    margin-top: 150px; 
    width: 100%;
  }

  #company-name {
    width: 100%;
    background-color: rgba(36, 36, 36, 0.4);
    margin-top: 2rem;
    margin-bottom: 5rem;
    padding: 10rem 5rem;
    color: #fff;
    text-align: left;
    font-family: 'Cinzel', serif;
    font-size: larger;
  }

  .menu {
    float:left;
    width: 30%;
    margin-bottom: -1000px;
    padding-bottom: 1000px;
  }

  .routes {
    float: right;
    width: 70%;
  }
}

@media only screen and (max-width: 999px) {
  .desktop {
    display: none;
  }

  .accordion {
    margin-top: 20px;
  }
  
  .accordion-title {
    cursor: pointer;
    padding: 1px;
    background-color: #555;
    border-radius: 10px 10px 10px 10px;
  }

  .accordion-content > ul {
    list-style: none;
    margin-right: 10%;
  }

  .routes {
    padding: 10px 0;
  }

  .menu {
    padding-top: 20px;
  }
}